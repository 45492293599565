.login_img {
  position: absolute;
  top: -200px;
}

@media (max-width: 575px) {
  .login_img {
    display: none;
  }
}
