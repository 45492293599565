/* wrapper */
.b24-widget-button-wrapper .b24-widget-button-position-bottom-right .b24-widget-button-visible {}

/* wrapper when b24 visible */
.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible.b24-widget-button-bottom {
  z-index: 30010;
}

.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible {
  right: 20px;
  bottom: 20px;
  z-index: 2000;
}

.b24-widget-button-shadow .b24-widget-button-show {
  z-index: 20020;
}

.b24-widget-button-shadow {
  z-index: 30000 !important;
}
