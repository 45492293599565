.not_found {
  .logo_error {
    text-align: center;
  }

  .inner {
    margin-top: 10px;
  }

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-top: 20px;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    }
  }

  .specials {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      font-size: 16px;
      line-height: 150%;
      text-align: center;
    }
  }

  .specials_btn {
    background: #FFD400;
    border-radius: 8px;
    padding: 16px;
    min-width: 300px;
    text-align: center;
    color: #000;
    text-decoration: none;
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}
