@keyframes spinner {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 85%;
  height: 85%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.spinner.black div {
  border-color: #000;
  border-top-color: transparent;
}

.spinner div {
  position: absolute;
  border: 20px solid #FFD400;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  box-sizing: content-box;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rolling {
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rolling.small {
  width: 25px;
  height: 25px;
  padding: 2px;
}

.rolling.small .spinner {
  width: 85%;
  height: 85%;
}

.rolling.small .spinner div {
  border-width: 2px;
}
