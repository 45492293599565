.plank {
  height: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media (min-width: 376px) {
    justify-content: flex-start;
  }
  @media (min-width: 1680px) {
    justify-content: center;
  }
}

.baner {
  height: 34px;
  object-fit: fill;
  overflow: hidden;

  @media (max-width: 375px) {
    content: url("/images/adBannerSmall.jpg");
    width: auto;
    max-width: 100%;
  }

  @media (min-width: 376px) {
    content: url("/images/adBannerBig.jpg");
    height: 28px;
    object-fit: cover;
    object-position: left;
  }
}
