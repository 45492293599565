.catalog {
  display: flex;
  align-items: center;
  position: relative;
}

.catalog_button {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background: #FFE589;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 3px;
}

.catalog_button span {
  margin-left: 10px;
}

.catalog_button {
  color: #000;
  text-decoration: none;
}

.catalog .sidebar_btn {
  border: none;
  outline: none;
  background: none;
  padding: 0;
}

@media (max-width: 991px) {
  .catalog {
    background: none;
  }
}
