.authorization > a {
  text-decoration: none !important;
}

.authorization > a,
.authorization > span {
  white-space: nowrap;
}

.authorization .inner:hover {
  cursor: pointer;
  text-decoration: underline;
}
