.cart_icon {
  display: flex;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.goods_count {
  position: absolute;
  top: -11px;
  left: 12px;
  z-index: 1;
  background: #DF3011;
  color: #FFF;
  border-radius: 50px;
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 17px;
  height: 17px;
  padding: 0 4px;
}

@media (max-width: 991px) {
  .goods_count {
    top: -12px;
    left: 10px;
    font-size: 11px;
  }
}
