.wrapper {
  display: inline-flex;
  position: relative;
}

.menuButton {
  cursor: pointer;
}

.items {
  width: max-content;
  padding: 5px 0;
  display: inline-flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.items.open {
  opacity: 1;
  z-index: 2;
}

.items.hidden {
  opacity: 0;
}

.items.bottom-right {
  top: calc(100% + 5px);
  left: 0;
}

.items.bottom-left {
  top: calc(100% + 5px);
  right: 0;
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  transition: all 0.25s ease;
  cursor: pointer;
}

.item:hover {
  background-color: #EEE;
}
