.socials span {
  text-align: center;
  font-weight: bold;
}

.socials .social_links {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
