@import "helpers/variables";
@import "helpers/normalize";
@import "fonts";
@import "base";
@import "modules/leaflet";
@import "modules/smartbanner";
@import "modules/b24";
@import "modules/pagination";

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 10px;
  }

  .lock_scroll_mobile {
    overflow: hidden !important;
  }

  .goods-page__sort-params {
    padding: 0 10px;
    display: flex;
  }

  .goods-page__sort-params .filter-btn {
    border: none;
    outline: none;
    background: #FFCF28;
    border-radius: 2px;
    padding: 10px;
    margin-right: 15px;
    flex: 0 1 50%;
  }

  .goods-page__sort-params .filter-btn img {
    margin-right: 10px;
    vertical-align: top;
  }
}
