.loader div {
  width: 25px;
  height: 25px;
}

.goods_count {
  width: 75px;
  display: flex;
  justify-content: space-between;
}

.goods_count button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 158.3%;
}

.goods_count_value {
  font-weight: 500;
  font-size: 14px;
  line-height: 158.3%;
  max-width: 50%;
  outline: none;
  text-align: center;
}

.goods_count_value.not_enough {
  color: rgb(0 0 0 / 20%);
}

.goods_count_value::-webkit-outer-spin-button,
.goods_count_value::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.goods_count_value {
  appearance: textfield; /* Firefox */
}

@media (max-width: 575px) {
  .text {
    margin-left: 7px;
    font-size: 11px;
  }
}
