.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
}

.call_header{
  font-size: 24px;
}

.phoneNum:hover{
  text-decoration: underline;
}

.contacts span {
  margin: 10px 0;
  font-weight: 900;
}

.contacts p {
  margin: 0 0 10px;
  font-weight: 900;
}

.contacts span a {
  text-decoration: none;
  color:#000;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
}

.apps {
  display: flex;
  align-items: center;
  gap: 10px;
}
