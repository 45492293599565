.location {
  &.bigMob {
    @media (max-width: 991.9px) {
      width: 100%;
    }

    & > button {
      @media (max-width: 991.9px) {
        width: 100%;
      }
    }
  }

  & > button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
  }
}

.location .location_img {
  margin-right: 10px;
  display: inline-flex;
  width: 16px;
}

.addressName {
  max-width: 170px;

  @media (min-width: 992px) {
    width: 300px;
  }

  &.bigMob {
    @media (max-width: 991.9px) {
      width: 100%;
      max-width: unset;
      text-align: left;
    }
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
