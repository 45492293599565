.account {
  margin-right: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.account > span {
  white-space: nowrap;
}

.account a {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.account a b {
  display: flex;
  flex-wrap: wrap;
  max-width: 118px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.account a b > span{
width: 110px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
text-align: center;
}

.account a:hover {
  text-decoration: underline;
}

.account > .account_image {
  display: inline-flex;
  margin-right: 10px;
}

.account button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}
