.pages_list ul {
  margin: 0;
  padding: 0;
}

.pages_list li {
  list-style-type: none;
  margin-bottom: 20px;
}

.pages_list a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000;
}

.pages_list a:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .pages_list li {
    text-align: center;
  }
}
