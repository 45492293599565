.pagination-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  margin: 0 5px;
  border: none;
  outline: none;
  cursor: pointer;
  background: #EEE;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  text-decoration: none;
  color: #000;
}

.pagination-arrow:disabled path {
  fill-opacity: 0.27;
}
