.button_switch {
  background-color: #F3F3F3;
  border: 3px solid #F3F3F3;
  border-radius: 7px;
  display: flex;
  height: 40px;
  max-width: 100%;
  min-height: 40px;
  position: relative;
  z-index: 2;
}

.button_switch_item {
  border-radius: 5px;
  align-items: center;
  color: #000;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-weight: bold;
  justify-content: center;
  position: relative;
  transition: color 0.35s ease-in;
  user-select: none;
}

.button_switch_item.active {
  background: #FFD400;
  cursor: unset;
}

.button_switch_item.disabled {
  color: #C4C4C4;
}

.button_switch_item .info {
  display: none;
  position: absolute;
  bottom: 130%;
  background: #FFCF28;
  padding: 10px;
  border-radius: 5px;
  color: #000;
  font-weight: normal;
  min-width: 100%;
  width: 190px;
  text-align: center;
}

.button_switch_item.disabled:hover .info {
  display: block;
}

.button_switch_item.disabled:hover::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #FFCF28;
  position: absolute;
  bottom: 112%;
}

.button_switch_item.disable_hover_effect:hover::after {
  display: none;
}
