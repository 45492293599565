.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  gap: 40px;
  background-color: #FFCF28;
}

.first_block,
.second_block {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;
}

@media (max-width: 920px) {
  .wrapper {
    flex-direction: column;
  }
}
