.price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.price .old_price {
  font-weight: 600;
  font-size: 16px;
  line-height: 158.3%;
  color: #747474;
  position: relative;
}

.price .old_price span {
  font-size: 13px;
}

.price .old_price::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: #747474;
}

.price .current_price {
  font-weight: 600;
  font-size: 24px;

}

.price .current_price span {
  font-size: 18px;
}

.price.discount .current_price {
  color: #DF3011;
}

@media (max-width: 575px) {
  .price .old_price {
    font-size: 12px;
    line-height: 148.8%;
  }

  .price .current_price {
    font-size: 20px;
    line-height: 158.3%;
  }
}
