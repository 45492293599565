.closest_market_container {
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 10001;
  width: 340px;
  border-radius: 5px;
}

.closest_market .closest_market_bg {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(0 0 0 / 20%);
  backdrop-filter: blur(5px);
  z-index: 1003;
}

.closest_market .inner {
  position: relative;
}

.closest_market .market_control {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.closest_market .market_control_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFF;
}

.closest_market .market_control_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.closest_market .market_control_image img {
  vertical-align: top;
}

.closest_market .market_close {
  width: 45px;
}

.closest_market .market_close .market_control_image {
  cursor: pointer;
  position: relative;
}

.closest_market .market_close .close_progress {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.closest_market .market_close .close_progress circle {
  stroke-dasharray: "285 9999";
  transition: all 1s linear;
}

.closest_market .market_image {
  background: #FFF;
  border-radius: 5px;
  box-shadow: 0 0 10px 6px rgb(0 0 0 / 15%);
}

.closest_market .market_image img {
  border-radius: 5px;
  vertical-align: top;
  filter: brightness(90%);
}

.closest_market .market_info {
  background: #FFF;
  display: flex;
  border-radius: 5px;
  position: relative;
  top: -10px;
  justify-content: center;
  box-shadow: 0 0 10px 6px rgb(0 0 0 / 15%);
}

.closest_market .market_info_item {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closest_market .title_container {
  width: 100%;
  display: flex;
}

.closest_market .market_info .info_title {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
}

.closest_market .market_info .info_title span {
  font-size: 12px;
  line-height: 14px;
  color: #8D8D8D;
  margin-bottom: 4px;
}

.closest_market .market_info .info_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.closest_market .market_info .info_button button {
  margin-bottom: 10px;
  padding: 0;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 50%;
  background: #FFD42F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 991px) {
  .closest_market_container {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
  }
}
