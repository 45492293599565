.goods_add_indicator {
  position: fixed;
  bottom: 10px;
  left: 0;
  z-index: 3000; /* 501 */
  width: 375px;
  max-width: 100%;
}

.goods_add_item {
  margin: 10px 10px 0;
  padding: 9px 15px;
  cursor: pointer;
  color: #FFF;
  background: rgb(0 0 0 / 75%);
  border-radius: 10px;
  position: relative;
  left: 0;
  display: flex;
  justify-content: space-between;
  animation: show_anim 0.5s;
}

.goods_add_item.hide {
  animation: hide_anim 0.5s;
  opacity: 0;
}

.goods_add_item .goods_add_content {
  width: 84%;
}

.goods_add_item .goods_add_content > h3 {
  font-size: 12px;
  line-height: 158.3%;
}

.goods_add_item .goods_add_good_title {
  font-size: 12px;
  line-height: 158.3%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.goods_add_item .goods_add_icon {
  margin-left: 10px;
}

.goods_add_item .goods_add_icon > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #FFD400;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

@keyframes show_anim {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes show_anim {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes hide_anim {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes hide_anim {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
