.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  > main {
    flex: 1 0 auto;
    background:#f5f5f5;
    border-bottom: 1px solid #3f3f3f;
  }

  > footer {
    flex: 0 0 auto;
    background: #f5f5f5;
  }

  .categories_list {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: all 0.5s ease;
    background: #FFF;
    z-index: 300010;
    border-right: 2px solid #CECECE;
  }
}
