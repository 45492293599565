.up_button {
  position: fixed;
  bottom: 96px;
  right: 25px;
  z-index: 100;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 30px;
  background: #FFCF28;
  color: #000;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  display: none;
  font-size: 25px;
  transform: rotate(-90deg);
  transition: transform 0.3s;
  box-shadow: -2px 2px 2px 1px rgb(178 178 178 / 20%);
}

.arrow {
  transform: rotate(-90deg);
}

.up_button:hover {
  transform: rotate(-90deg) scale(1.2);
}

.up_button_show {
  display: flex;
  justify-content: center;
  align-items: center;
}
