.mobile_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0 / 58%);
  z-index: 30005;
  overflow: auto;
}

.mobile_sidebar .sidebar_body {
  z-index: 502;
  width: 300px;
  position: absolute;
  top: 0;
  transition: transform 0.5s;
  bottom: 0;
  background: #FFF;
}

.mobile_sidebar .sidebar_top {
  background: #FFD400;
  padding: 15px 0 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile_sidebar .sidebar_lang {
  margin-top: 21px;
}

.mobile_sidebar .sidebar_lang a {
  margin: 0 10px;
  color: #000;
  text-decoration: none;
  font-size: 12px;
  line-height: 180%;
}

.mobile_sidebar .sidebar_lang a.active_lang {
  font-weight: bold;
  text-decoration: underline;
}

.mobile_sidebar .sidebar_lang span {
  font-weight: 500;
}

.mobile_sidebar .sidebar_choosemarket {
  width: 100%;
  background: #F1F1F1;
  padding: 10px 15px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  text-decoration: none;
  border: none;
}

.mobile_sidebar .sidebar_choosemarket span {
  font-size: 10px;
  line-height: 180%;
  margin: 0;
}

.mobile_sidebar .socials {
  padding: 20px;
  background: #FFF;
}

.sidebar_buttons {
  background: #FFF;
  border-bottom: 1px solid #C4C4C4;
}

.sidebar_buttons_item > a {
  text-decoration: none;
}

.sidebar_buttons_item .sidebar_buttons_inner {
  padding: 15px 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  outline: none;
}

.sidebar_buttons_item .item_image {
  width: 70px;
  display: flex;
  justify-content: center;
}

.sidebar_buttons_item p {
  line-height: 180%;
  color: #000;
  margin: 0;
  text-decoration: none;
}
