.bottom_buttons {
  border-top: 1px solid #C4C4C4;
  background-color: #f5f5f5;
}

.bottom_item .bottom_btn {
  width: 100%;
  background: none;
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 180%;
  color: #000;
  text-decoration: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #C4C4C4;
}

.bottom_item .bottom_btn .arrow {
  transition: transform 0.5s;
}

.bottom_item .bottom_content {
  width: 100%;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.bottom_item .bottom_content > :first-child {
  margin: 20px 20px 0;
}

.rotate {
  transform: rotate(180deg);
}
