.search {
  flex: 1;
}

.search.active {
  z-index: 1007;
}

.search .search_inner {
  display: flex;
  position: relative;
  height: 100%;
}

.search .search_inner > :first-child {
  width: 100%;
  height: 100%;
}

.search .search_inner input {
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 0 40px 0 20px;
  height: 36px;
  outline: none;
}

.search .search_inner .search_btn {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search .search_hints {
  position: relative;
  top: -5px;
}

@media (min-width: 991px) {
  .open_input {
    border-radius: 5px 5px 0 0 !important;
  }
}

@media (min-width: 991px) {
  .search {
    max-width: 750px;
  }
}

@media (max-width: 991px) {
  .search .search_inner {
    flex: 1 1 auto;
  }

  .search .search_inner input {
    height: 32px;
    padding: 0 36px 0 10px;
  }

  .search .search_inner .search_btn {
    right: 3px;
  }

  .search .search_hints {
    position: static;
  }
}
