@keyframes fade-removing {
  100% {
    max-height: 0;
  }
}

.cart_goods {
  width: 100%;
}

.cart_goods_item {
  margin-bottom: 17px;
  box-shadow: 0 0 7px 1px rgb(0 0 0 / 10%);
  border-radius: 6px;
  padding: 12px;
  position: relative;
  background: #FFF;

  /* overflow-x: hidden; */

  /* overflow-y: hidden; */
  max-height: 500px;
}

.cart_goods_item.remove {
  transition: transform 1s ease, max-height 0.5s ease, padding 0.5s ease, margin 0.5s ease, opacity 0.5s ease;
  transform: translateX(-100%);
  max-height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.cart_goods_item .not_avalible {
  position: absolute;
  top: 0;
  left: -1px;
  z-index: 10;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  padding: 5px 10px;
  border-radius: 6px 0 3px;
}

.cart_goods_item .not_enough_item {
  background: #DF3011;
  color: #FFF;
}

.cart_goods_item .primary_item {
  background: #FFD42F;
}

.cart_goods_item .close {
  /* width: 25px; */

  /* height: 25px; */

  border: none;
  outline: none;
  padding: 0;
  background: none;
  position: absolute;
  top: 7px;
  right: 4px;
  cursor: pointer;
}

.cart_goods_item .close img {
  width: 16px;
  height: 16px;
}

.cart_goods_item .inner {
  display: flex;
  justify-content: space-between;
}

.cart_goods_item .goods_preview {
  flex: 0 1 110px;
  margin-right: 10px;
}

.cart_goods_item .goods_preview img {
  object-fit: contain;
}

.cart_goods_item.not_enough .goods_preview {
  opacity: 0.2;
}

.cart_goods_item .goods_content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cart_goods_item .goods_title {
  max-height: 36px;
  overflow: hidden;
  margin-right: 20px;
}

.cart_goods_item .goods_title a {
  font-size: 12px;
  line-height: 158.3%;
  text-decoration: none;
  color: #000;
}

.cart_goods_item.not_enough .goods_title {
  color: rgb(0 0 0 / 20%);
}

.cart_goods_item .price {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart_goods_item .price span {
  font-size: 12px;
  line-height: 180%;
  color: #A8A8A8;
}

.cart_goods_item.not_enough .price span {
  color: rgb(168 168 168 / 20%);
}

.cart_goods_item .price_value {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.cart_goods_item.not_enough .price_value {
  color: rgb(0 0 0 / 20%);
}

/* .cart_goods_item .warning:hover .warning_text { */

/*    display: block; */

/* } */

/* .cart_goods_item .warning_text::after { */

/*    content: ''; */

/*    width: 0; */

/*    height: 0; */

/*    border-left: 6px solid transparent; */

/*    border-right: 6px solid transparent; */

/*    */

/*    border-top: 6px solid rgba(0, 0, 0, 0.8); */

/*    position: absolute; */

/*    left: 50%; */

/*    bottom: 0; */

/*    transform: translateY(100%) translateX(-50%); */

/* } */

/* .cart_goods_item .warning b { */

/*    margin-left: 7px; */

/* } */

.cart_goods_item .cost {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .cart_goods_item .cross { */

/*    display: flex; */

/*    align-items: center; */

/* } */

.cart_goods_item.not_enough .cross {
  opacity: 0.2;
}

.cart_goods_item .cost_value {
  font-size: 14px;
  line-height: 18px;
}

.cart_goods_item.not_enough .cost_value {
  color: rgb(0 0 0 / 20%);
}

/* .cart_goods_item.not_enough .goods_count button,
.cart_goods_item.not_enough .goods_count button:disabled {
    color: rgba(0, 0, 0, 0.2);
} */

.attention {
  display: flex;
  align-items: center;
}
