.wrapper {
  padding: 8px;
  font-size: 12px;
  border-radius: 2px;
}

.wrapper.warning {
  background-color: #FDE8C9;
  border: 1px solid #F90;
}

.wrapper.success {
  background-color: #D8F8EB;
}
