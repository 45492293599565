.categoriesList {
  height: 100vh;
  position: relative;
}

.categoriesList_items {
  padding: 30px 0;
  height: 100vh;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .categoriesList {
    display: none;
  }
}

.category {
  padding: 2px 8px;
  width: 310px;
  margin-bottom: 10px;
}

.scrollBtn {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 10000000;
  height: 30px;
  bottom: 0;
  background: #FFCF28;
  position: absolute;
  font-weight: bold;

  &::before,
  &::after {
    content: "↓";
    font-size: 1.1em;
    padding: 0 10px;
  }

  :hover {
    cursor: pointer;
  }
}

.category .category_title {
  padding: 8px 12px;
  line-height: 180%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  text-decoration: none;
  background: #FFF;
  border-radius: 4px;
}

.category.active .category_title {
  background: #FFCF28;
}

.category .category_name {
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.category .category_name > p {
  margin: 0 0 0 8px;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category .category_name > img {
  max-width: 20px;
}

.category.active > .arrow rect {
  fill: #000;
}
