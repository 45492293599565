@keyframes smoothShowing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 40%);
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  z-index: 30006; /* 3000 */
  overflow: auto;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  animation: smoothShowing 0.2s ease forwards;
}

.modal .modal_body {
  background: #FFF;
  border-radius: 10px;
  min-width: 434px;
  position: relative;
}

.modal .close_btn {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 20px;
  right: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.modal .close_btn > img {
  display: block;
  width: 100%;
  height: 100%;
}

.modal .modal_control {
  margin-bottom: 17px;
  padding: 20px 20px 0;
}

.modal .modal_control span {
  padding-right: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #232323;
  margin: 0;
}

.modal .modal_control > button {
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.modal .modal_control img {
  margin: 0;
  padding: 0;
  vertical-align: top;
}

.modal .modal_inner {
  padding: 0 20px 20px;
}

.extra {
  position: relative;
  top: 0;
}

@media (max-width: 575px) {
  .modal .modal_body {
    min-width: 100%;
    border-radius: 0;
  }
}
