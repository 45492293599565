.categoryItem {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  background: #FFF;
  padding: 30px;
  border-left: 2px solid #CECECE;
  transition: all 0.5s ease;
}

.categoryItem.show {
  transform: translateX(310px);
}

.categoryItem .close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.categoryItem .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  background: #FFF;
}

.categoryItem .header h3 {
  font-size: 20px;
  padding-left: 25px;
}

.categoryItem .header > a {
  color: #000;
  text-decoration: none;
}

.categoryItem .header_image {
  align-self: stretch;
  margin-left: 20px;
  flex: 1;
  background: url("/images/categories/header-bg.svg");
}

.categoryItem .sub_categories {
  padding: 10px;
  background: #FFF;
  min-height: 560px;
  width: 830px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.categoryItem .categoryItem_specials_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  text-decoration: none;
}

.categoryItem .categoryItem_specials_item img {
  max-width: 100%;
}

.categoryItem .categoryItem_specials_item span {
  font-weight: bold;
  font-size: 20px;
  line-height: 180%;
  color: #0B0B0B;
  margin-top: 12px;
}

.categoryItem .categoryItem_lists {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 510px;
  overflow: hidden;
}

.categoryItem .categoryItem_lists_item {
  margin-right: 30px;
  width: 250px;
}

.categoryItem .categoryItem_lists_item span {
  font-weight: bold;
  font-size: 16px;
  line-height: 180%;
}

.categoryItem .categoryItem_lists_item span a {
  color: #0B0B0B;
  text-decoration: none;
}

.categoryItem .categoryItem_lists_item span a:hover,
.categoryItem .categoryItem_lists_item ul a:hover {
  text-decoration: underline;
}

.categoryItem .categoryItem_lists_item ul {
  padding: 0;
  margin: 0 0 20px;
}

.categoryItem .categoryItem_lists_item li {
  list-style-type: none;
}

.categoryItem .categoryItem_lists_item li a {
  color: #0B0B0B;
  text-decoration: none;
  font-size: 14px;
  line-height: 180%;
}

.categoryItem .categoryItem_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryItem .categoryItem_btn a {
  padding: 12px 17px;
}

.categoryItem .categoryItem_btn img {
  margin-left: 15px;
  margin-right: 0;
}

.categoryItem .sub_categories {
  width: 550px;
}

.categoryItem .show_all {
  font-weight: bold;
}
