html {
  //width: 100vw;
  // overflow-x: hidden;
  font-size: 10px;
}

body {
  font-family: Geometria, sans-serif;
  font-size: 1.4rem;
  height: 100vh;
  -webkit-overflow-scrolling: touch;

  /* TODO: temporary solution, need to fix (scrollbar crawls on the page) */
}

* {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  font-size: inherit;
  font-weight: map-get($font-weights, "regular");
  line-height: 1.5;
  margin: 15px 0;
}

a {
  // TODO: Uncomment styles below but it's will
  // broke some links styles in the project
  // Hot fix:
  // text-decoration: none;
  // color: inherit;
  font-size: inherit;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

textarea {
  resize: none;
}

/*
 * Restore styles for `ul`, `ol`
*/
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

/*
 * Containers
*/
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding: 0 15px;
  margin: 0 auto;
}

.container-sm {
  max-width: map-get($containers, "sm");
}

.container-md {
  max-width: map-get($containers, "md");
}

.container-lg {
  max-width: map-get($containers, "lg");
}

.container-xl {
  max-width: map-get($containers, "xl");
}

/*
 * Button
*/
.btn {
  background: #FFD400;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 17px;
  border-radius: 2px;
  color: #000;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
  cursor: pointer;
}

.btn:disabled {
  background: #E5E5E5;
  cursor: not-allowed;
}

.btn:hover:not(:disabled) {
  background: #F5CB00;
}

.link {
  text-decoration: underline;
  color: #3342AA;
  cursor: pointer;
}

.hide {
  display: none;
}

/*
 * Warning
*/
.warning-info {
  padding: 8px;
  font-size: 12px;
  border: 1px solid #F90;
  background-color: #FDE8C9;
  border-radius: 2px;
}

/*
 * Scroll styling
*/
.scroll {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: rgb(0 0 0 / 10%);
    border-radius: 25px;
  }

  &::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

/*
 * Media
*/
@media (min-width: 920px) {
  .show_on_mobile {
    display: none !important;
  }
}

@media (max-width: 920px) {
  .hide_on_mobile {
    display: none !important;
  }
}
