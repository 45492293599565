.footer {
  
}

.footer__inner {
  display: grid;
  grid-template-columns: auto 2fr 2fr 2fr;
  grid-template-areas:
    "logo information partners contacts"
    "socials information partners contacts";
  padding-top: 60px;
}

.footer .logo {
  grid-area: logo;
}

.footer .socials {
  grid-area: socials;
}

.footer .information,
.footer .partners {
  display: flex;
  justify-content: center;
}

.footer .information {
  grid-area: information;

  /* margin-left: 100px; */
}

.footer .partners {
  grid-area: partners;
}

.footer .contacts {
  grid-area: contacts;
}

@media (max-width: 991px) {
  .footer {
    margin-top: 0px;
  }

  .footer__inner {
    padding-top: 20px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "logo"
      "socials"
      "information"
      "partners"
      "contacts";
  }

  .footer .logo {
    display: none;
  }

  .footer .socials {
    margin-bottom: 10px;
  }
}
