.warning {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding-top: 12px;
  cursor: default;
  position: relative;
}

.warning::before {
  content: "";
  width: calc(100% + 24px);
  height: 2px;
  background-color: #EEE;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.image_wrapper {
  display: inline-flex;
}

.text {
  margin-left: 7px;
  font-weight: 700;
  font-size: 12px;
}

@media (max-width: 575px) {
  .image_wrapper {
    width: 14px;
    height: 14px;
  }
}
