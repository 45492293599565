.search_hints {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #FFF;
  overflow: auto;
  border-radius: 5px;
}

.search_hints .all_results {
  text-align: right;
  margin-top: 10px;

  :hover {
    text-decoration: underline;
  }
}

.search_hints .all_results a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
}

.search_hints .hint_content {
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  padding: 10px 20px;
}

.search_hints .hint_content:first-of-type {
  border-top: 1px solid rgb(0 0 0 / 20%);
}

.search_hints .hint_content:last-child {
  border-bottom: none;
}

.search_hints .hint_good {
  display: flex;
  padding: 5px 0;
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid rgb(0 0 0 / 10%);

  &:last-of-type {
    border-bottom: none;
  }
}

.search_hints .hint_good_image {
  height: 69px;
  flex: 0 0 69px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_hints .hint_good_image img {
  max-width: 69px;
  max-height: 69px;
}

.search_hints .hint_good_inner {
  padding: 5px 15px;
}

.search_hints .hint_good_inner h3 {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
}

.search_hints .hint_good_inner h3 > span {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.search_hints .hint_price_old > span {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #AEAEAE;
  position: relative;
}

.search_hints .hint_price_old > span::after {
  content: "";
  width: 110%;
  height: 2px;
  background: #F73D3D;
  position: absolute;
  top: 7px;
  left: 0;
  transform: rotate(-20deg);
}

.search_hints .hint_price_current > span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.search_hints .hint_text {
  display: flex;
  color: #000;
  text-decoration: none;
  padding: 10px 0;
}

.search_hints .hint_text:first-child {
  padding-top: 5px;
}

.search_hints .hint_text:last-child {
  padding-bottom: 5px;
}

.search_hints .hint_text_image {
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.search_hints .hint_text_inner {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.search_hints .hint_text_inner > p {
  margin: 0;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
}

.search_hints .hint_text_inner > p > span {
  font-weight: 300;
}

@media (max-width: 991px) {
  .search_hints {
    position: fixed;
    top: 48px;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .search_hints .all_results {
    text-align: left;
  }

  .search_hints .hint_goods .hint_good:nth-child(n+4) {
    display: none;
  }
}

@media (max-width: 767px) {
  .search_hints .hint_good_image img {
    width: 55px;
  }
}
