.bread_crumbs {
  margin: 0 0 15px;
  padding: 15px 0;
  display: flex;
  overflow: auto;
}

.bread_crumbs::-webkit-scrollbar {
  height: 0;
  background-color: transparent;
}

.bread_crumbs_item {
  margin-right: 10px;
}

.bread_crumbs_item,
.bread_crumbs_item a {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  color: #C4C4C4;
  text-decoration: none;
  white-space: nowrap;
  transition: all linear 0.3s;
}

.bread_crumbs_item a:hover {
  color: #000;
}

.bread_crumbs_item:last-child,
.bread_crumbs_item:last-child a {
  font-weight: 500;
  color: #4C4C4C;
}

.bread_crumbs .bread_crumbs_item:last-child svg {
  display: none;
}

.bread_crumbs_item svg {
  margin-left: 10px;
}
