.smartbanner-show.smartbanner-margin-top {
  margin-top: 0;
}

.smartbanner-show .smartbanner {
  display: block;
}

.smartbanner {
  display: none;
  left: 0;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  overflow: hidden;
  background: #F2F2F2;
  box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  position: fixed;
  z-index: 9998;
}

.smartbanner-top {
  position: absolute;
  top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 14px;
  padding: 0 0 1px;
  background-color: transparent;
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-family: Arial;
  color: #888;
  text-shadow: 0 1px 0 #FFF;
  -webkit-font-smoothing: none;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: #888;
}

.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-icon,
.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
}

.smartbanner-info {
  white-space: normal;
  width: calc(99% - 201px);
  font-size: 11px;
  line-height: 1.2em;
  color: #6A6A6A;
  text-shadow: 0 1px 0 hsl(0deg 0% 100% / 80%);
  font-weight: 300;
}

.smartbanner-wrapper {
  max-width: 110px;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4D4D4D;
  white-space: normal;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
  display: none;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-author:empty + .smartbanner-description {
  max-height: 50px;
}

.smartbanner-button {
  display: inline-block;
  margin: auto 0;
  padding: 0 10px;
  height: 24px;
  min-width: 10%;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0 1px 0 hsl(0deg 0% 100% / 80%);
  color: #0C71FD;
  border: none;
  box-shadow: none;
  border-radius: 3px;
  background: #FFD400;
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: #F2F2F2;
}

.smartbanner-button-text {
  background: #FFD400;
  border: none;
  border-radius: 3px;
  color: #000;
}
