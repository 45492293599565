.package {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.package .package_add_image {
  display: flex;
  justify-content: flex-end;
  max-height: 56px;
}

.package .package_add_image > .package_add_text {
  display: none;
  margin-bottom: 10px;
}

.package .package_image {
  display: flex;
  justify-content: center;
}

.package .package_image > img {
  vertical-align: top;
  object-fit: contain;
}

.package .package_add {
  flex: 1;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 4px 1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.package_add_item.sold {
  cursor: not-allowed;
}

.package_add_item.sold > div {
  opacity: 0.5;
}

.package_add::before {
  content: "";
  width: 180px;
  height: 150px;
  position: absolute;
  top: -94px;
  left: 40%;
  z-index: -1;
  transform: translateX(-50%);
  background: url("/images/cart/package-add.svg") center/contain no-repeat;
}

.package_add_item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 17px;
  position: relative;
  cursor: pointer;
  width: 33.3333%;
  background-color: #FFF;
}

.package_add_item:first-child {
  border-radius: 5px 0 0 5px;
}

.package_add_item:last-child {
  border-radius: 0 5px 5px 0;
}

.package_add_item:nth-child(2)::before,
.package_add_item:nth-child(2)::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: #E5E5E5;
}

.package_add_item:nth-child(2)::before {
  left: 0;
}

.package_add_item:nth-child(2)::after {
  right: 0;
}

.package_add_item .package_image {
  justify-content: flex-start;
}

.package_add_item .package_image > img {
  margin-right: 5px;
  width: auto;
  max-width: 100%;
}

.package_add_item .package_title {
  text-align: center;
}

.package_add_item .package_price {
  font-weight: bold;
}

.package_add_item .package_content > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

@media (max-width: 575px) {
  .package_add_item .package_preview img {
    width: 25px;
  }
}

@media (max-width: 400px) {
  .package {
    padding-top: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .package .package_add_image {
    justify-content: flex-start;
  }

  .package_add::before {
    display: none;
  }

  .package .package_add_image > .package_add_text {
    display: block;
  }
}
