@font-face {
  font-family: Geometria;
  src:
    url("../../public/fonts/Geometria-Medium.woff2") format("woff2"),
    url("../../public/fonts/Geometria-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Geometria;
  src:
    url("../../public/fonts/Geometria.woff2") format("woff2"),
    url("../../public/fonts/Geometria.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Geometria;
  src:
    url("../../public/fonts/Geometria-Bold.woff2") format("woff2"),
    url("../../public/fonts/Geometria-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
