.sidebar_buttons {
  background: #FFF;
  border-bottom: 1px solid #C4C4C4;
}

.sidebar_buttons_item > a,
.sidebar_buttons_item > button {
  padding: 15px 0;
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
}

.sidebar_buttons_item .item_image {
  min-width: 70px;
  display: flex;
  justify-content: center;
}

.sidebar_buttons_item .item_image img {
  /* transform: translateX(-10px); */
  max-width: 20px;
  max-height: 20px;
}

.sidebar_buttons_item p {
  line-height: 180%;
  color: #000;
  margin: 0;
}
