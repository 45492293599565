.cart_void a {
  text-decoration: none;
}

.cart_void a:hover {
  text-decoration: underline;
}

.cart_void > p {
  margin-bottom: 0;
}

.cart_void > span {
  font-weight: bold;
}

.cart .inner {
  display: flex;
  justify-content: space-between;
}

.cart_goods {
  width: 416px;
  margin-right: 70px;
}

.cart_offer {
  width: 339px;
}

.edit_checkout {
  border: 0;
  outline: 0;
  background: none;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_checkout a {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #B3B3B3;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.edit_checkout a > img {
  margin-right: 10px;
}

.offer_info .actual_address {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #9A9A9A;
  padding-bottom: 17px;
}

.offer_info .actual_address .title {
  font-size: 12px;
  line-height: 14px;
}

.offer_info .actual_address .address {
  margin-top: 12px;
  font-size: 14px;
  line-height: 16px;
}

.offer_info .goods_count {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-top: 10px; */
}

.offer_info .goods_count .count {
  font-size: 18px;
  line-height: 16px;
}

.offer_info .goods_count .goods_price {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.offer_info .min_cost {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  color: #FF5454;
}

.offer_info .min_cost .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.offer_info .min_cost .cost {
  margin-top: 6px;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}

.offer_info .offer_btn {
  margin-top: 24px;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  background: #FFD400;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: #000;
}

.offer_info .offer_btn:disabled {
  background: #E5E5E5;
}

.offer_info .offer_btn img {
  margin-right: 10px;
}

.offer_info .not_allowed_info {
  margin-top: 20px;
  color: #FF5454;
}

@media (max-width: 991px) {
  .cart_goods {
    margin-right: 30px;
  }

  .cart_offer {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .cart .inner {
    flex-direction: column;
  }

  .cart_goods {
    margin-right: 0;
    width: 600px;
    max-width: 100%;
  }

  .cart_offer {
    width: auto;
    margin-top: 30px;
  }
}
