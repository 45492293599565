.item_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper {
  padding: 0px;
  text-decoration: none;
  text-align: center;
  color: black;
  height: 76px;

  :hover {
    cursor: pointer;
  }
}

.category_link {
  background: #E3E3E2;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 16px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}