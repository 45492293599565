.chat_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  text-align: center;
  line-height: 30px;
  color: #000;
  border-radius: 50%;
  border: none;
  background: #FFCF28;
  position: fixed;
  right: 25px;
  bottom: 26px;
  width: 56px;
  height: 56px;
  z-index: 100;
  transform: rotate(-270deg);
  transition: transform 0.3s;
  box-shadow: -2px 2px 2px 1px rgb(178 178 178 / 20%);
  cursor: pointer;

  &:hover {
    transform: rotate(-270deg) scale(1.2);
  }

  &.hidden {
    display: none;
  }

  .chat_icon {
    transform: rotate(-90deg);
  }
}

.blackout_wrapper {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1005;
  background-color: rgb(33 33 33 / 30%);
}

.wrapper {
  display: none;
  position: fixed;
  flex-direction: column-reverse;
  bottom: 26px;
  right: 25px;
  z-index: 1000;

  &.opened {
    display: flex;
  }
}

.contact_item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  text-align: center;
  color: white;
  border-radius: 50%;
  border: none;
  background-color: #FFF100;
  width: 56px;
  height: 56px;
  z-index: 200;
  transition: transform 0.3s;
  box-shadow: -2px 2px 2px 1px rgb(178 178 178 / 20%);
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  &.telegram {
    background-color: #2FC6F6;
  }

  &.phones:hover::before,
  &.telegram:hover::before {
    content: "TA-DA! Chat";
    display: block;
    font-size: 10px;
    color: black;
    background-color: rgb(255 255 255 / 60%);
    border-radius: 20px;
    padding: 0 10px;
    position: absolute;
    top: 25%;
    left: -150%;
  }

  &.phones:hover::before{
    content: "Контакти";
  }

  &.phones {
    background-color: #FFF100;

    @media (min-width: 991px) {
      // display: none;
    }
  }

  &.close {
    background-color: #EBEFF2;
  }
}
